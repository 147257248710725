<template>
	<div>
		<mov-gastos-add-new
		:is-add-new-mov-gastos-sidebar-active.sync="isAddNewMovGastosSidebarActive"
		@refetch-data="cargaMovimientos()"
		/>
		<b-card no-body class="mb-0">
			<!-- Filtrado -->
			<div class="m-2">
				<b-row>
					<b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Mostrar</label>
						<v-select class="per-page-selector d-inline-block mx-50"
							v-model="perPage"
							:options="pageOptions"
							:clearable="false"
						/>
						<label>registros</label>
					</b-col>
					<b-col cols="12" md="6">
						<div class="d-flex align-items-center justify-content-end">
							<b-input-group class="mr-1">
								<b-form-input
									type="search"
									placeholder="Buscar..."
									v-model="filter"
								/>
								<b-input-group-append>
									<b-button class="p-0 px-1"
										variant="secondary"
										:disabled="!filter"
										@click="filter = '';"
									>
										<feather-icon icon="XIcon" size="21"/>
									</b-button>
								</b-input-group-append>
							</b-input-group>
							<b-button
								variant="primary"
								@click="isAddNewMovGastosSidebarActive = true"
							>
								<span class="text-nowrap">Nuevo Gasto</span>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div> <!-- Filtrado -->

			<b-table
				stacked="md"
				show-empty
				small
				class="position-relative"
				empty-text="No se encontraron registros"
				:items="data"
				:fields="columns"
				:current-page="currentPage"
				:per-page="perPage"
				:filter="filter"
				@filtered="onFiltered"
			>
				<template #cell(folio)="data">
					<b class="text-primary">{{data.item.folio}}</b>
				</template>

				<template #cell(fecha)="data">
					<div class="text-wrap"> 
						<span class="align-text-top">{{ formatDate(data.item.fecha) }}</span>
					</div>
				</template>

				<template #cell(malla)="data">
					<b-media vertical-align="center">
					
						<b-link
						:to="{ /*name: 'apps-users-view', params: { id: data.item.id }*/ }"
						class="font-weight-bold d-block text-nowrap"
						>
						{{ data.value.nombre }}
						</b-link>
						<small class="text-muted">@{{ data.value.descripcion }}</small>
					</b-media>
				</template>

				 <!-- Column: User -->
				 <template #cell(cat_gasto)="data">
					<b-media vertical-align="center">
					
					<b-link
						:to="{ /*name: 'apps-users-view', params: { id: data.item.id }*/ }"
						class="font-weight-bold d-block text-nowrap"
					>
						{{ data.value.nombre }}
					</b-link>
					<small class="text-muted">@{{ data.value.descripcion }}</small>
					</b-media>
				</template>

				<template #cell(creador)="data">
					<b-media vertical-align="center">
						<template #aside>
						<b-avatar
							size="30"
							:text="avatarText(data.value.name)"
							:variant="`light-primary`"
							:to="{ /*name: 'apps-users-view', params: { id: data.item.id } */}"
						/>
						</template>
						<span
						:to="{ /*name: 'apps-users-view', params: { id: data.item.id }*/ }"
						:class="`text-primary`"
						class="font-weight-bold d-block text-nowrap"
						>{{ data.value.name }}
						</span>
					</b-media>
				</template>

				<template #cell(cantidad)="data">
					<div class="text-wrap"> 
						<span class="align-text-top font-weight-bold text-danger">{{ formatMoney(data.item.cantidad) }}</span>
					</div>
				</template>

				<template #cell(horas_trabajadas)="data">
					<div class="text-wrap"> 
						<span class="align-text-top text-dark">{{ data.item.horas_trabajadas }}</span>
					</div>
				</template>
			
				<!-- Column: User -->
				<template #cell(centro_costo)="data">
					{{ data.value.malla.nombre }} - {{ data.value.nombre }}
				</template>
		
				<template #cell(acciones)="data">
					<div class="text-nowrap">
						<b-dropdown
							variant="link"
							no-caret
						>
							<template #button-content>
								<feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
							</template>
							<b-dropdown-item
								:to="{name: 'mov-ajuste-fecha-antiguedad-editar', params: { id: data.item.Id }, }"
							>
								<feather-icon icon="EditIcon" class="text-warning" />
								<span class="align-middle ml-50">Editar</span>
							</b-dropdown-item>
							<b-dropdown-item  @click="desactivarMovimiento(data.item)">
								<feather-icon icon="TrashIcon" class="text-danger" />
								<span class="align-middle ml-50">Eliminar</span>
							</b-dropdown-item>
						</b-dropdown>
					</div>
				</template>
			</b-table>

			<!-- Paginacion -->
			<div class="mx-2 mb-2">
				<b-row>
					<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">
							Mostrando {{ dataMeta.from }} de {{ dataMeta.to }} de {{ dataMeta.of }} registros
						</span>
					</b-col>
					<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
						<b-pagination class="mb-0 mt-1 mt-sm-0"
							v-model="currentPage"
							:total-rows="totalRows"
							:per-page="perPage"
							first-number
							last-number
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div> <!-- Paginacion -->
		</b-card>
	</div>

</template>

<script>
	import {
		BTab, BTabs, BCard, BAlert, BLink, BForm, BFormGroup, BFormInput, BTable, BBadge,
		BDropdown, BDropdownItem, BPopover, BInputGroup, BInputGroupAppend, BPagination,
		BFormTextarea, BFormInvalidFeedback, BButton, BFormSelect, BRow, BCol, BFormCheckbox, BMedia, BAvatar
	} from 'bootstrap-vue'
    import axios from "@axios"
	import router from "@/router";
	import { ValidationObserver, ValidationProvider, } from "vee-validate";
	import { required, alphaNum, email, numeric } from "@validations";
	import vSelect from "vue-select";
    import utilerias from '@/views/utilerias'
    import moment from 'moment';   
	import { avatarText } from '@core/utils/filter'
	import movGastosAddNew from './movGastosAddNew.vue'

	const {
		msgToast,
		msjConfirmarDesactivar,
	} = utilerias()

	export default {
		components: {
			movGastosAddNew,
			BTab, BTabs, BCard, BAlert, BLink, BForm, BFormGroup, BFormInput, BTable, BBadge,
			BDropdown, BDropdownItem, BPopover, BInputGroup, BInputGroupAppend, BPagination,
			BFormTextarea, BFormInvalidFeedback, BButton, BFormSelect, BRow, BCol, BFormCheckbox,
			vSelect,
			ValidationProvider,
			ValidationObserver,
			BMedia,
			BAvatar,
		},
		setup() {
			return {
				avatarText			
			}
		},
		data() {
			return {
				isAddNewMovGastosSidebarActive : false,
				data: null,
				columns: [
					{ key: "malla", label: 'Malla', sortable: true },		
					{ key: "cat_gasto", label: 'Tipo de Gasto', sortable: true },						
					{ key: 'fecha', label: 'Fecha', sortable: true },
					{ key: 'cantidad', label: 'Cantidad', sortable: true },
					{ key: 'folio', label: 'Folio', sortable: true },	
					{ key: 'creador', label: 'Registrado por', sortable: true },
					{ key: 'acciones' },
				],
				totalRows: 0,
				currentPage: 1,
				perPage: 10,
				pageOptions: [5, 10, 25, 50, 100],
				filter: null,
			};
  		},
  		computed: {
			dataMeta() {
				return {
					from: this.perPage * (this.currentPage - 1) + 1,
					to: (this.perPage*this.currentPage) < this.totalRows ? this.perPage * this.currentPage : this.totalRows,
					of: this.totalRows,
				}
    		}
  		},
		async mounted() {
			await this.cargaMovimientos();
   	  		 this.totalRows = this.data.length
		},
  		methods: {
      		onFiltered(filteredItems) {
				this.currentPage = 1
				this.totalRows = filteredItems.length
			},
   			getValidationState({ dirty, validated, valid = null }) {
				return dirty || validated ? valid : null;
			},
  		  	async cargaMovimientos() {
				const r = await axios.get(`/api/movimientos-gastos`)
				this.data = r.data.data.data;
    		},
			desactivarMovimiento(item) {
				msjConfirmarDesactivar.fire({}).then(async (result) => {
					if (result.isConfirmed) {
						const r = await axios.delete(`/api/movimientos-gastos/${item.id}`, { withCredentials: true })
						msgToast.fire({ icon: 'success', title: 'Movimiento de gasto eliminado' });
						await this.cargaMovimientos();
					}
				});
			},
			resolveMovimientoActivoVariant(status) {
				if (status === 'Autorizado') return 'success'				
				if (status === 'Cancelado') return 'danger'
				if (status === 'Rechazado') return 'danger'
				return 'secondary'
			},
			formatDate(date) {
				if (!date) return null
				return moment(date).format('DD/MM/YYYY')
			},
			randomColor() {
				const colors = ['primary', 'success', 'danger', 'warning', 'info', 'secondary']
				return colors[Math.floor(Math.random() * (colors.length))]
			},
			formatMoney(value) {
				//format to mexican currency
				var formatter = new Intl.NumberFormat('es-MX', {
				style: 'currency',
				currency: 'MXN',
				minimumFractionDigits: 2
				});

				return formatter.format(value);
			},
  		}
	};
</script>

<style>
	.content-header .breadcrumb {
		display: flex !important;
	}
</style>
